import { CertificateItemBrief } from '../../../model';
import { UserAction } from '../../../model/actions/user.action';
import { useTranslation } from 'react-i18next';
import { CertificateService } from '../../../api/certificate/certificate.service';
import { useQueryClient } from 'react-query';
import { resetQueryFn } from '../../../utils';
import { EntityQueryKey } from '../../../query/query.keys';

type AvailableActions = 'revoke' | 'renew';

export function useActions(): Record<AvailableActions, UserAction<CertificateItemBrief>> {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return {
    revoke: {
      key: 'revoke',
      btnText: () => t('certificate:button.revoke'),
      btnColor: () => 'error',
      available: (entity) => entity?.isRevoked === false,
      action: async (entity) => {
        await CertificateService.revoke(entity?.uuid ?? '');
        await queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, EntityQueryKey.Employee) });
      },
      confirm: () => ({
        description: 'certificate:confirm.revoke',
      }),
    },
    renew: {
      key: 'renew',
      btnText: () => t('certificate:button.renew'),
      btnColor: () => 'success',
      available: (entity) => entity != null && entity.isActual && (entity.isRevoked || entity.isValid),
      action: async (entity) => {
        await CertificateService.renew(entity?.uuid ?? '');
        await queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, EntityQueryKey.Employee) });
      },
      confirm: () => ({
        description: 'certificate:confirm.renew',
      }),
    },
  }
}